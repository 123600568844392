<!-- 营养餐 -->
<template>
  <div class="page-main">
    <header class="page-header">
      <div class="header-content">营养餐</div>
    </header>
    <section class="page-info">
      <div class="bind-opt">
        <van-button type="success" round block @click="handClickGoOrder">进入点餐</van-button>
      </div>
      <div style="margin:20px 0;">
        <div class="base-info">
          <div class="base-info-header">
            <van-divider
              :style="{ borderColor: '#9F88FF',margin: '0px 0px' }"
            />
            <div class="info-title">
              <span style="background: #9F88FF;padding:0 10px;">身体基本信息</span>
            </div>
          </div>
          <div class="person-base-info">
            <div class="person-item">
              运动量:187kcal
            </div>
            <div class="person-item">
              年龄:54岁
            </div>
            <div class="person-item">
              身高:166cm
            </div>
            <div class="person-item">
              过敏源:芹菜
            </div>
            <div class="person-item">
              体重:60kg
            </div>
            <div class="person-item">
              运动目标:未填写
            </div>
          </div>
        </div>
      </div>
      <div class="recommend-package">
        <div class="recommend-header">
          <van-icon name="fire-o" size="18px" />营养餐匹配结果
        </div>
        <div class="package-list">
          <div class="package-item">
            <div class="img-info">
              <van-image
                width="100px"
                height="100px"
                fit="cover"
                radius="5"
                src="https://pic.carnoc.com/file/181206/18120612051933.jpg"
              />
              <div class="recommend-num">
                01
              </div>
              <div class="recommend-ratio">
                匹配度:95%
              </div>
            </div>
            <div class="text-info">
              <div class="text-header">
                贝塔和牛营养谷物碗
              </div>
              <div class="info-price">
                ￥26.8
              </div>
            </div>
          </div>
          <div class="package-item">
            <div class="img-info">
              <van-image
                width="100px"
                height="100px"
                fit="cover"
                radius="5"
                src="https://pic.carnoc.com/file/181206/18120612051933.jpg"
              />
              <div class="recommend-num">
                02
              </div>
              <div class="recommend-ratio">
                匹配度:95%
              </div>
            </div>
            <div class="text-info">
              <div class="text-header">
                贝塔和牛营养谷物碗
              </div>
              <div class="info-price">
                ￥26.8
              </div>
            </div>
          </div>
          <div class="package-item">
            <div class="img-info">
              <van-image
                width="100px"
                height="100px"
                fit="cover"
                radius="5"
                src="https://pic.carnoc.com/file/181206/18120612051933.jpg"
              />
              <div class="recommend-num">
                03
              </div>
              <div class="recommend-ratio">
                匹配度:95%
              </div>
            </div>
            <div class="text-info">
              <div class="text-header">
                贝塔和牛营养谷物碗
              </div>
              <div class="info-price">
                ￥26.8
              </div>
            </div>
          </div>
          <div class="package-item">
            <div class="img-info">
              <van-image
                width="100px"
                height="100px"
                fit="cover"
                radius="5"
                src="https://pic.carnoc.com/file/181206/18120612051933.jpg"
              />
              <div class="recommend-num">
                04
              </div>
              <div class="recommend-ratio">
                匹配度:95%
              </div>
            </div>
            <div class="text-info">
              <div class="text-header">
                贝塔和牛营养谷物碗
              </div>
              <div class="info-price">
                ￥26.8
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "meal",
  components: {},
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      //获取传入参数
      const options = $router.currentRoute.value.query;
      console.log(options)
    });

    const data = reactive({
    });
    const handClickGoOrder = () => {
      $router.push({
        name: 'makeOrder'
      })
    }

    return {
      ...toRefs(data),
      handClickGoOrder
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  margin-bottom: 80px;
  .page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;
    background: #9F88FF;
    color: #FFFFFF;
    .btn-left {
      position: fixed;
      left: 16px;
      background-color: #efeff4;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
    }
    .header-content {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      flex: 1;
      font-weight: 700;
    }
  }
  .page-info {
    height: 100%;
    margin: 20px;
    .info-title {
      color: #FFFFFF;
      width: 100%;
      height: 100%;
      font-size: 18px;
      text-align: center;
    }
    .base-info {
      position: relative;
      background: #F5FFFA;
      .base-info-header {
        position: absolute;
        top: 0;
        width: 100%;
      }
      .person-base-info {
        padding: 30px 0 0 0;
        min-height: 200px;
      }
    }
    .recommend-package {
      width: 100%;
      height: 100%;
      margin: 20px 0;
      .recommend-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
        font-size: 18px;
        line-height: 30px;
        font-weight: bold;
        color: #9F88FF;
      }
      .package-list {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
      }
      .package-item:first-child {
        margin-left: 0px;
      }
      .package-item:last-child {
        margin-right: 0px;
      }
      .package-item {
        width: 100px;
        margin: 0 10px;
        .img-info {
          position: relative;
          height: 100px;
          width: 100px;
          .recommend-num {
            color: #FFFFFF;
            background: #9F88FF;
            max-width: 50px;
            min-width: 50px;
            font-size: 14px;
            text-align: center;
            position: absolute;
            border-radius: 5px 0 0 0;
            top: 0;
            left: 0;
          }
          .recommend-ratio {
            color: #FFFFFF;
            background: #9F88FF;
            max-width: 80px;
            min-width: 80px;
            font-size: 12px;
            text-align: center;
            position: absolute;
            border-radius: 0 0 5px 0;
            bottom: 0;
            right: 0;
          }
        }
        .text-info {
          .text-header {
            font-size: 18px;
            line-height: 20px;
            margin: 5px 0;
          }
          .info-price {
            color: #FF3333;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }
}

</style>
